
import { Activity, DailyReport ,AdapterActivity } from './../models';

export enum StatusReportActivity {
  EMPTY = -1,
  PENDING = 0,
  REGISTERED = 1,
  VALIDATED = 2
}

export enum StatusActivity {
  NO_RECORD = -1,
  ALLOW = 0,
  BLOCKED = 1
}


const emptyDailyReport = {
  id: '',
  status: StatusReportActivity.EMPTY
}

const statusReportName = (statusValue:number):string => {
  switch (statusValue) {
      case StatusReportActivity.EMPTY:
          return 'Inexistente'
      case StatusReportActivity.PENDING:
          return 'Pendiente';
      case StatusReportActivity.REGISTERED:
          return 'Registrado'
      case StatusReportActivity.VALIDATED:
          return 'Validado';
      default:
          return 'Estatus por definir.'
  }
}

const statusActivityName = (statusValue:number):string => {
  switch (statusValue) {
    case StatusActivity.NO_RECORD:
          return 'Sin registro'
      case StatusActivity.ALLOW:
          return 'Activa'
      case StatusActivity.BLOCKED:
          return 'Bloqueada';
      default:
          return 'Estatus por definir.'
  }
}

const getDailyReport = (dailyReport:DailyReport) =>{
  return {...dailyReport, statusName: statusReportName(dailyReport.status)}
}

export const createActivityAdapter = (activity: Activity):AdapterActivity => ({
    accumulatedValue: activity.accumulatedValue,
    dailyReport: getDailyReport(activity?.dailyReport ?? emptyDailyReport),
    description: activity.description,
    disapprovedValue: activity.disapprovedValue,
    id: activity.id,
    logo: activity.logo,
    logo2: activity.logo2,
    pendingValue: activity.pendingValue,
    order: activity.order,
    status: activity.status,
    statusName: statusActivityName(activity.status),
    timesDone: activity.timesDone,
    title: activity.title,
    value: activity.value,
  });

  export const createActivities = (activities: Activity[])=>{
    return activities.map((activity:Activity)=>createActivityAdapter(activity));
  }

import { activities, activitiesHistorical, welcome, positionResults, general } from '../states';

import {createStore} from 'vuex';
 
const store = createStore({
    modules:{
        activities,
        activitiesHistorical,
        welcome,
        positionResults,
        general
    }
});
 
export default store;

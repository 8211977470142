import { createApp } from 'vue'
import store from './redux/store';
import App from './App.vue'
import router from './router';
import VueEasyLightbox from 'vue-easy-lightbox'
import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Custom Style */
import './theme/custom.scss'

/* FontAwesome */
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fat } from '@fortawesome/pro-thin-svg-icons'

// Video Player
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

// Virtual Infinite Scroll
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import VueVirtualScroller from 'vue-virtual-scroller'

// SweetAlert 2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-easy-lightbox/external-css/vue-easy-lightbox.css'


/* add icons to the library */
library.add(fas, far, fal, fad, fat)

const app = createApp(App)
  .use(IonicVue, { mode: 'md'})
  .use(router)
  .use(store)
  .use(VueVideoPlayer)
  .use(VueVirtualScroller)
  .use(VueSweetalert2)
  .use(VueEasyLightbox)
  .component('font-awesome-icon', FontAwesomeIcon);
    
router.isReady().then(() => {
  app.mount('#app');
});

import { PositionResult } from "@/models";

interface Payload {
    data: PositionResult
}

const getDefaultState = ():PositionResult => ({
    me: {
        gradeId: '',
        group: '',
        groupId: '',
        index: 0,
        school: '',
        schoolId: '',
        score: 0,
        stateId: '',
        stateNanme: '',
        status: 0,
        student: '',
        studentId: ''
    },
    rankList: []
 });
  
const state:PositionResult = JSON.parse(JSON.stringify(getDefaultState()))

const getters = {
    getRankList: () => state.rankList,
    getMyPosition: () => state.me,
    getPositions: () => ({...state})
};
 
const actions = {};
 
const mutations = {
    setPositionResults(state:PositionResult, payload: Payload){
        state.me = payload.data.me;
        state.rankList = payload.data.rankList;
    },
    updateMyPosition(state:PositionResult, payload: Payload){
        state.me = payload.data.me;
    },
    updateRankList(state:PositionResult, payload: Payload){
        state.rankList = payload.data.rankList;
    },
    reset(state:PositionResult) {
        Object.assign(state, getDefaultState())
    }
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

import {  Welcome } from '@/models';
import { WelcomeEmptyState } from '../redux/states/welcome';
export const createWelcomeAdapter = ({goal, roletofulfill, bills, enrollmentId, family, familyImage, history, id, _Goal, studentName}:Welcome) => {
   try{
    return{
      bills,
      enrollmentId,
      family,
      familyImage,
      goal,
      goalNumber: _Goal,
      history,
      roletofulfill,
      id,
      studentName
    }
   } catch(_err){
    return WelcomeEmptyState;
   } 
  };
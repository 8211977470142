const messages = {
    POSITION_RESULTS: {
        HELP_MODALS: [
            {
                IMG: `<img src='https://pentatlon.blob.core.windows.net/images/statics/pentatlon/modal-ranking-help.png' class='help-message-img' loading='lazy'/>`,
                TEXT: `<h1 class='help-message-description'>En esta pantalla podrás visualizar la posición de los grupos, y los puntos obtenidos, estando en el número uno el grupo de mayor puntuación (estos van cambiando en tiempo real, conforme vayan los alumnos contestando sus retos).</h1>`
            }
        ]
    },
    DASHBOARD: {
        I_AM: 'Soy',
        HEADER_TITLE: "MI PERFIL",
        HEADER_SUBTITLE: "Dar inicio a tu aventura",
        GO_TO_WORK: "INICIAR",
        FAMILY_HISTORY: "Mi historia",
        THIS_IS_WHAT_I_HAVE: "Este es el rol que me tocó",
        MY_ECONOMIC_GOAL: 'Mi meta económica',
        MY_FAMILY: 'ASÍ SOY YO',
        HELP_TITLE: `<h1 class='help-message-description'>En esta pantalla se muestra el perfil de tu familia asignada.</h1>`,
        HELP_IMG: `<img src='https://pentatlon.blob.core.windows.net/images/statics/pentatlon/modal-family-help.png' class='help-message-img' loading='lazy'/>`
    },
    USER_MENU: {
        HELP: 'Ayuda',
        SILENCE: 'Silencio',
        GO_TO_SOFIAXT: 'Ir a SofíaXT',
        OPEN_TOUR: 'Tour'
    },
    MODAL_VIDEO: {
        HEADER_TITLE: '¿CÓMO FUNCIONA?',
        HEADER_DESCRIPTION: 'En este video te explicamos en qué consiste el reto de vida saludable. ¡Pongamos juntos en acción cuerpo, mente, pies y manos!',
        CLOSE_VIDEO: 'Cerrar Video',
        FOOTER_TITLE: 'Este video sólo aparecerá una vez pero podrás consultarlo en la sección de ayuda las veces que lo desees dando clic en el ícono del perfil y seleccionando ayuda.'
    },
    MODAL_MESSAGE: {
        TITLE: '¡Actividad enviada exitosamente!',
        BODY: 'Recuerda que tu dinero virtual se verá reflejado una vez que tu tutor apruebe tu actividad enviada a revisión.',
    },
    HEADER_CIRCLE: {
        BACK: 'atras',
        MESSAGE_TOP:'¿Cuánto debo ganar para cubrir los gastos mensuales de mi familia?',
        MESSAGE_MIDDLE:'En base a la familia que tienes, debes de GANAR:',
        ECONOMIC: 'Económica',
        FILTER_PHASES:"FILTRAR POR FASES"
    },
    ACTIVITIES_PAGE: {
        GREETINGS: '¡Hola Amig@!',
        MESSAGE_1: 'Me da gusto verte motivado en realizar la misma actividad:',
        MESSAGE_2: 'Sin embargo solo puedes realizarlo una vez al día.',
        DETAILS: 'Ver detalle',
        SHARE: 'compartir',
        GOAL: "Meta",
        HELP_TITLE: `<h2>En esta pantalla se muestran los gastos asignados a tu familia.</h2>`,
        HELP_SHARE_BUTTON: `<b>Si tienes actividades por revisar, puedes hacer clic en este icono para compartir el enlace de revisión.</b>`,
        HELP_MODALS: [
            {
                IMG: `<img src='https://pentatlon.blob.core.windows.net/images/statics/pentatlon/modal-activities-help.png' class='help-message-img' 
                loading='lazy'/>`,
                TEXT: `<h1 class='help-message-description'>En esta pantalla se muestran las actividades que puedes realizar para lograr tu meta económica, y  puedes visualizar el progreso que llevas para alcanzar tu meta.</h1>`
            },
            {
                IMG: `<img src='https://pentatlon.blob.core.windows.net/images/statics/pentatlon/modal-activity-help.png' class='help-message-img' 
                loading='lazy'/>`,
                TEXT: `<h1 class='help-message-description'>Si tienes actividades por validar, puedes dar clic en el ícono de compartir de la actividad que ya realizaste y enviar el enlace para validación de tus papás.</h1>`
            }
        ]
    },
    ACTIVITY_PAGE: {
        TITLE: '¡Felicidades! Has avanzado muy bien. Aquí están tus resultados:',
        APPROVED: 'aprobado',
        ON_CHECKING: 'en revisión',
        REJECTED: 'no aprobado',
        UPLOAD_EVIDENCE: 'SUBIR EVIDENCIA',
        OPTIONAL: 'Opcional',
        CANCEL: 'Cancelar',
        SHARE: 'compartir',
        STEP_1: 'Paso 1. Cuéntame qué fue lo que hiciste',
        STEP_2: 'Paso 2. Agrega una imagen de lo que hiciste',
        STEP_3: '',
        SEND: 'Enviar',
        HELP_TITLE: `<h2>Aquí puedes ver el resumen de esta actividad y las opciones para registrar una nueva</h2>`,
        HELP_ON_CHECKING: `<b> Se muestra el precio de actividades en espera por ser revisadas.</b>`,
        HELP_APPROVED: `<b>Se muestra el precio de actividades aprobadas.</b>`,
        HELP_REJECTED: `<b> Se muestra el precio de actividades rechazadas.</b>`,
        HELP_DESCRIPTION: `<b> Descríbenos cómo realizaste esta actividad.</b>`,
        HELP_UPLOAD: `<b>Sube una foto de la evidencia de esta actividad.</b>`,
        HELP_SUBMIT: `<b>Una vez completados los campos, haz clic en Enviar para finalizar y registrar la actividad.</b>`,
        TITLE_STEP_1: 'Aquí puedes agregar una imagen como soporte a la actividad realizada',
        HELP_MODALS: [
            {
                IMG: `<img src='https://pentatlon.blob.core.windows.net/images/statics/pentatlon/modal-activity-page-help.png' class='help-message-img' loading='lazy'/>`,
                TEXT: `<h1 class='help-message-description'>En esta pantalla puedes ver el resumen de la actividad realizada</h1>`
            },
            {
                IMG: `<img src='https://pentatlon.blob.core.windows.net/images/statics/pentatlon/modal-pending-activity-help.png' class='help-message-img' loading='lazy'/>`,
                TEXT: `<h1 class='help-message-description'>Se muestra el dinero de las actividades por validar</h1>`
            },
            {
                IMG: `<img src='https://pentatlon.blob.core.windows.net/images/statics/pentatlon/modal-approved-activity-help.png' class='help-message-img' loading='lazy'/>`,
                TEXT: `<h1 class='help-message-description'>Se muestra el dinero de las actividades validadas por tus papás</h1>`
            },
            {
                IMG: `<img src='https://pentatlon.blob.core.windows.net/images/statics/pentatlon/modal-rejected-activity-help.png' class='help-message-img' loading='lazy'/>`,
                TEXT: `<h1 class='help-message-description'>Se muestra el dinero de las actividades rechazadas.</h1>`
            },
            {
                IMG: `<img src='https://pentatlon.blob.core.windows.net/images/statics/pentatlon/modal-activity-step1-help.png' class='help-message-img' loading='lazy'/>`,
                TEXT: `<h1 class='help-message-description'>Descríbenos cómo realizaste esta actividad.</h1>`
            },
            {
                IMG: `<img src='https://pentatlon.blob.core.windows.net/images/statics/pentatlon/modal-activity-step2-help.png' class='help-message-img' loading='lazy'/>`,
                TEXT: `<h1 class='help-message-description'>Sube una foto de la evidencia de esta actividad.</h1>`
            },
        ]
    },
    MODAL_ACTIVITY: {
        TITLE:'¡Actividad enviada exitosamente!',
        BODY: 'Recuerda que tu dinero virtual se verá reflejado una vez que tu tutor apruebe tu actividad enviada a revisión.'
    },
    BILLS_PAGE: {
        NEXT: 'Siguiente',
        SKIP: 'Omitir',
        GOAL: 'Meta',
        HELP_TITLE: `<h1 class='help-message-description'>En esta pantalla se muestran los gastos asignados a tu familia.</h1>`
    },
    HELP_PAGE: {
        HELP: 'AYUDA',
        TUTORIAL: 'VIDEO TUTORIAL'
    },
    VALIDATE_ACTIVITIES_PAGE: {
        SELECT_ALL: '',
        SELECTED_ACTIVITIES: '',
        DID: 'si se hizo',
        DIDNT: 'no se hizo',
        HEADER_TITLE: 'ACTIVIDADES POR VALIDAR'
    },
    VALIDATE_ACTIVITY_PAGE: {
        HEADER_TITLE: '',
        STUDENT_COMMENTS: 'Comentarios del alumno',
        GET_REWARD: 'Recibirá de recompensa si apruebas esta actividad',
        SUCCESSFUL_SENT: '¡Actividad enviada exitosamente!',
        REMEMBER: 'Recuerda que tu dinero virtual se verá reflejado una vez que tu tutor apruebe tu actividad enviada a revisión.',
        DID: 'si se hizo',
        DIDNT: 'no se hizo',
        MESSAGE_1: 'Hola',
        MESSAGE_2: `quiere que le califiques esta actividad. Si consideras que realmente la hizo, dale clic en <b>El botón verde "SI SE HIZO"</b>, en el caso que consideres que no fue realizado correcta mente, daremos clic en <b>El botón rojo "NO SE HIZO"`,
        ACTIVITY_VALIDATED: '¡Actividad Evaluada Exitosamente!',
        ACTIVITY_REJECTED: '¡ACTIVIDAD RECHAZADA!',
        ACTIVITY_REJECTED_FOOTER: 'Gracias por ayudarnos a calificar según, el código de honestidad.',
        ALERT_ERROR_ALREADY_VALIDATE: 'La actividad ya se ha validado previamente.',
        
    },
    HISTORY_ACTIVITIES_PAGE: {
        HELP_MODALS: [
            {
                IMG: `<img src='https://pentatlon.blob.core.windows.net/images/statics/pentatlon/modal-historical-activity-help.png' class='help-message-img' loading='lazy'/>`,
                TEXT: `<h1 class='help-message-description'>En esta pantalla podrás ver el historial de las actividades que has realizado, el precio y su estatus.</h1>`,
            },
            {
                IMG: `<img src='https://pentatlon.blob.core.windows.net/images/statics/pentatlon/modal-share-icon-help.png' class='help-message-img' loading='lazy'/>`,
                TEXT: `<h1 class='help-message-description'>Recuerda que si das clic en el botón de “compartir” puedes enviar el enlace para validar por whastapp o correo electrónico a tus papás.</h1>`,
            },
        ],
        HEADER_TITLE: 'MIS ACTIVIDADES',
        CLOSE:'Cerrar',
        SEARCH_PLACEHOLDER: '',
        FILTER: 'Filtrar',
        SENT: 'Enviado',
        BACK: 'Atrás',
        AT: 'a las',
        APPLY: 'Aplicar',
        DID: 'si se hizo',
        DIDNT: 'no se hizo',
        SEARCH_ACTIVITY: 'Buscar actividad',
        AMOUNT_RECEIVED: 'Importe recibido',
        SHOW_MORE: 'Ver más',
        TO_RECEIVED: 'Por recibir',
        FILTER_TYPE_STATUS: 'TIPO DE STATUS',
        ALL_ACTIVITIES: 'Todas las actividades',
        PENDINGS_TO_VALIDATE: 'Pendientes de Validar',
        PENDING_TO_VALIDATE: 'Pendiente de Validar',
        APPOVED: 'Aprobada',
        APPROVED_ACTIVITIES: 'Aprobadas',
        NOT_APPROVED_ACTIVITIES: 'No Aprobadas',
        FILTER_PERIOD: 'FILTRO DE PERIODO',
        FILTER_MODAL_HEADER: 'Filtros de Búsqueda',
        PERIOD_TIME_TITLE: {
            ACTIVITIES: 'Actividades en los últimos',
            ALL: 'Cualquier periodo',
            TODAY: 'Hoy',
            LAST_WEEK: 'Última semana',
            LAST_MONTH: 'Últimos 30 días',
            LAST_3_MONTHS: 'Últimos 3 meses',
            DAYS: 'días'

        },
        HELP_TITLE: `<h2>En esta pantalla podrás ver el historial de las actividades que has realizado, el precio y su estatus.Recuerda que puedes hacer click en el botón de “compartir” para enviar el enlace de revisión de la actividad. </h2>`
    },
    HISTORY_FILTER_MODAL: {
        HELP_MESSAGE: `En esta pantalla podrás realizar una búsqueda de las actividades realizadas anteriormente. Para buscar, selecciona las diferentes opciones y haz click en “Aplicar`,
        HELP_MODALS: [
            {
                IMG: `<img src='https://pentatlon.blob.core.windows.net/images/statics/pentatlon/modal-historical-filter-help.png' class='help-message-img' loading='lazy'/>`,
                TEXT: `<h1 class='help-message-description'>En esta pantalla podrás realizar una búsqueda de las actividades realizadas anteriormente. Para buscar, selecciona las diferentes opciones y haz click en “Aplicar” </h1>`
            }
        ]
    },
    SHARE_MODAL: {
        INDICATIONS: "Al dar clic en la actividad que pedirás te validen, puedes copiar el enlace y mandarlo por whatsapp o por correo electrónico a tu papá, mamá o tutor.(no olvides pedirle el correo electrónico a la persona que te va a estar validando las actividades).",
        ALERT_COPY_LINK: "Se ha copiado la liga para que puedas compartirla a tu mamá, papá o tutor, para que pueda acceder a calificar tu actividad.",
        COPY_LINK: 'Copiar link:'
    },
    TOUR_MODAL: {
        MODAL_1:{
            title:'¡Hola Amig@!',
            message: 'Bienvenido a esta nueva aventura social. Déjame te platico que ahora será tu turno de ser el papá o mamá en una familia imaginaria que se te asignará de forma aleatoria y que te presentaremos más adelante.',
        },
        MODAL_2:{
            title:'',
            message: "Tu rol en esta dinámica será de proveedor económico por lo que se te asignará una meta económica basado en un desglose de gastos que tendrás que cubrir para que tu familia tenga lo necesario para vivir."
        },
        MODAL_3:{
            title:'',
            message: "Si ya sé, te vas a preguntar y  ¿Cómo voy a ganar el dinero  para mantener esta familia imaginaria? Verás, te vamos a dar un listado de actividades donde tú serás libre de elegir entre ellas y hacerlas las veces que quieras.",
        },
        MODAL_4:{
            title:'',
            message: 'Cada una de estas actividades tiene un valor económico que recibirás como recompensa una vez que confirme la persona que te evaluará, que sí hayas realizado la actividad.',
            bottom_image: '',
            bottom_message: ''
        },
        MODAL_5:{
            title: '',
            message:`<p>No me queda duda que lo lograrás con mucho éxito.</p> 
                <br/> <b>¡Ahora sí!</b> <br/> Es momento que conozcas a tu familia virtual <br/> <b style="text-align:center">¡Qué Emoción!</b>`
        },
        
    },
}

export default messages

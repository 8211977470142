import {
  ActivitiesHistorical,
  Activity,
  ActivityReport,
  ActivityValidator,
  Indicator,
  PositionResult,
  PositionResultsXTData,
  Welcome
} from '@/models';
import { loadAbort } from '@/utilities';
import axios from 'axios';
import { string } from 'yup';

export const urlAnonLoadActivity = 'https://pentatlonxtapi.azurewebsites.net/api/Activiteit/';

const instance = () =>
  axios.create({
    baseURL: 'https://pentatlonxtapi.azurewebsites.net/api/',
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'User-Enrollment-Id': `${localStorage.getItem('User-Enrollment-Id')}`
    }
  });

const instanceSofiaXT = () =>
  axios.create({
    baseURL: 'https://www.sofiaxt.com/WebApi/',
    //baseURL: 'https://sofiaxt2webapp-testing.azurewebsites.net/WebApi/',
    
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  });

const instanceBlob = () =>
  axios.create({
    baseURL: 'https://sofiaxtstorage.azurewebsites.net/api/blobs',
    timeout: 10000,
    headers: {
      Authorization: 'b9184d8c-ebc9-41fc-bfa2-d4a50f98e262',
      'Content-Type': 'multipart/form-data'
    }
  });

const instanceAnonymousValidator = () =>
  axios.create({
    baseURL: urlAnonLoadActivity,
    timeout: 5000,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Anonymous-Management-Id': '4c4ed3ec-89d1-475f-baf8-12d5dc72e594'
    }
  });

export const getAnonymousValidatorActivity = (activityId: string) => {
  const controller = loadAbort();
  return {
    call: instanceAnonymousValidator().get<ActivityValidator>(activityId, { signal: controller.signal }),
    controller
  };
};

export const postAnonymouseValidateActivity = (reportId: string) => {
  const controller = loadAbort();
  const apiUrl = 'Valideren';

  return {
    call: instanceAnonymousValidator().post<any>(apiUrl, { id: reportId }, { signal: controller.signal }),
    controller
  };
};

export const postAnonymouseDeclineActivity = (reportId: string) => {
  const controller = loadAbort();
  const apiUrl = 'Afwijzen';
  return {
    call: instanceAnonymousValidator().post<any>(apiUrl, { id: reportId }, { signal: controller.signal }),
    controller
  };
};

export const postBlobContent = (Content: File | FormData) => {
  const controller = loadAbort();
  const apiUrl = '';

  return {
    call: instanceBlob().post(apiUrl, Content, { signal: controller.signal }),
    controller
  };
};

export const getWelcome = () => {
  const controller = loadAbort();
  const apiUrl = 'Welcome';
  return {
    call: instance().get<Welcome>(apiUrl, { signal: controller.signal }),
    controller
  };
};

export const getActivities = () => {
  const controller = loadAbort();
  const apiUrl = 'Activities';

  return {
    call: instance().get<Activity>(apiUrl, { signal: controller.signal }),
    controller
  };
};

export const getIndicator = () => {
  const controller = loadAbort();
  const apiUrl = 'Welcome/Indicator';

  return {
    call: instance().get<Indicator>(apiUrl, { signal: controller.signal }),
    controller
  };
};

export const postActivity = (ActivityData: ActivityReport) => {
  const controller = loadAbort();
  const apiUrl = 'ReportActivity/Send';

  return {
    //!! check why is this modal Indicator
    call: instance().post<Indicator>(apiUrl, ActivityData, { signal: controller.signal }),
    controller
  };
};

export const getResultPositionsXT = (PositionResultsXTData: PositionResultsXTData) => {
  const controller = loadAbort();
  const apiUrl = 'Concurso/_ResultsConfiguration';

  return {
    call: instanceSofiaXT().post<PositionResult>(apiUrl, PositionResultsXTData, { signal: controller.signal }),
    controller
  };
};

export const getConcursoData = (idPhase: string) => {
  const controller = loadAbort();
  const apiUrl = 'Concurso/GetData?idmaratonfase=';

  return {
    call: instanceSofiaXT().get<PositionResult>(`${apiUrl}${idPhase}`, { signal: controller.signal }),
    controller
  };
};

export const getResultPositions = () => {
  const controller = loadAbort();
  const apiUrl = 'Dashboard/_Results';

  return {
    call: instance().get<PositionResult>(apiUrl, { signal: controller.signal }),
    controller
  };
};

export const getActivitiesHistorical = () => {
  const controller = loadAbort();
  const apiUrl = 'Activities/Historical';

  return {
    call: instance().get<ActivitiesHistorical>(apiUrl, { signal: controller.signal }),
    controller
  };
};

// import { ActivityHistorical, ReportHistorical } from './../models/activityHistorical.model';
import {ActivityHistorical, ReportHistorical, Report} from '@/models'
import { FormatDateDayjs } from '@/utilities'

enum Status {
  PENDING = 0,
  APPROVED = 1,
  NOT_APPROVED = 2
}


export const statusReportName = (statusValue:number):string => {
  switch (statusValue) {
      case Status.PENDING:
          return 'Pendiente';
      case Status.APPROVED:
          return 'Aprobado'
      case Status.NOT_APPROVED:
          return 'No Aprobado';
      default:
          return 'Status por definir.'
  }
}

export const createActivityHistoricalAdapter = (activities: ActivityHistorical[]) => {
  const response:ReportHistorical[] = [];
  activities
  .sort((_act1:ActivityHistorical, _act2: ActivityHistorical ) =>( _act1.order > _act2.order ) ? 1 : -1 )
  .forEach(
      (_activity:ActivityHistorical)=>{
       const item:ReportHistorical[] = _activity.reports.map((_report:Report)=>({
          activityName: _activity.description,
          activityValue: _activity.value,
          activityLogo: _activity.logo,
          activityId: _activity.id,
          reportComments: _report.comments,
          reportDate: _report.date,
          reportDateValue: FormatDateDayjs(_report.date, "DD/MM/YYYY"),
          reportId: _report.id,
          rerportModifiedDate: _report.modifiedDate,
          reportModifiedTime: _report.modifiedTime,
          reportName: _report.name,
          reportPhoto: _report.photo,
          reportStatus: _report.status,
          reportStatusName: statusReportName(_report.status),
          reportTime: _report.time
      }))

      response.push(...item)

  })
    return response
  }

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "message message-container" }
const _hoisted_2 = { class: "container-cubix" }
const _hoisted_3 = { class: "cubix" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HelpTooltip = _resolveComponent("HelpTooltip")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createBlock(_component_ion_modal, {
    ref: "modal",
    class: "modalMessage fullscreen",
    "is-open": _ctx.isOpen
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        class: "ion-padding message-content",
        fullscreen: true
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_HelpTooltip, {
              message: `
                <div style='text-align:center'>
                  <h1 class='help-message-description'>En esta pantalla podrás visualizar la posición de tu grupo y los puntos obtenidos.</h1>
                </div>
                `,
              "close-modal": _ctx.handleClosePopOver,
              "is-open": _ctx.handleOpenPopOver
            }, null, 8, ["close-modal", "is-open"]),
            _createVNode(_component_ion_button, {
              class: "close-button-modal",
              onClick: _ctx.handleModalCloseModal
            }, {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-xmark" })
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_ion_grid, {
              class: "ion-padding",
              fixed: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, { size: "12" }, {
                      default: _withCtx(() => [
                        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "left-header-text" }, "TOP", -1)),
                        _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-medal" })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPositions, (position) => {
                  return (_openBlock(), _createBlock(_component_ion_row, {
                    key: position.index,
                    class: "ion-margin"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, { class: "position" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(position.index), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_ion_col, { class: "group" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(position.group), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_ion_col, { class: "score" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(position.score), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("img", {
                  src: _ctx.images.cubix,
                  loading: "lazy"
                }, null, 8, _hoisted_4)
              ])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-open"]))
}
import { ActivityHistorical ,ActivitiesHistorical, Report, ReportHistorical } from "@/models";
enum Status {
    PENDING = 0,
    APPROVED = 1,
    NOT_APPROVED = 2
}

const getDefaultState = ():ActivitiesHistorical => ({
    activities: []
 });

const state:ActivitiesHistorical = JSON.parse(JSON.stringify(getDefaultState()))

export const statusReportName = (statusValue:number):string => {
    switch (statusValue) {
        case Status.PENDING:
            return 'Pendiente';
        case Status.APPROVED:
            return 'Aprobado'
        case Status.NOT_APPROVED:
            return 'No Aprobado';
        default:
            return 'Status por definir.'
    }
}

type Getters ={
    getActivities():ActivityHistorical[],
    countReports():number
}

const getters: Getters = {
    getActivities: () => state.activities,
    countReports: () => {
        let counter = 0;
        for(const reporstLength of state.activities.map((act:ActivityHistorical)=>act.reports.length)){
            counter += reporstLength;
        }
        return counter;
    }
}
 
const actions = {};
 
const mutations = {
    setActivities(state:any, payload: any){
        state.activities = payload.data
    },
    reset(state:any) {
        Object.assign(state, getDefaultState())
    }
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}


import { Activity } from '@/models';

export interface ActivityHistorical {
  activities: Activity[];
  selectedActivity: string;
}

const getDefaultState = () => ({
  activities: <Activity[]>[],
  selectedActivity: <string>''
});

const state: ActivityHistorical = JSON.parse(JSON.stringify(getDefaultState()));

const getters = {
  getSelectedActivity(state: any) {
    return state.activities.find((activity: Activity) => activity.id === state.selectedActivity) ?? null;
  },
  getSelectedActivityId(state: any) {
    return state.selectedActivity;
  },
  getActivities: (state: any) => state.activities
};

const actions = {};

const mutations = {
  setActivities(state: any, payload: any) {
    state.activities = payload.data;
  },
  setSelectedActivity(state: any, payload: any) {
    state.selectedActivity = payload.data;
  },
  updateActivityStatus(state: any, payload: any) {
    const activityIndex = state.activities.findIndex((_act: any) => _act.id === payload.data);
    state.activities[activityIndex].status = 1; // NEED ADD SHARED CONST STATUS ACTIVITY
  },
  reset(state: any) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

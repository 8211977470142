<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent, onMounted } from 'vue';
import { getIndicator } from '@/services/public.service';
import { useFetchAndLoad } from '@/hooks';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  setup() {
    const store = useStore();
    const { callEndpoint } = useFetchAndLoad();
    const fetchAndUpdateIndicator = async () => {
      try {
        const { data } = await callEndpoint(getIndicator());
        store.commit('general/setIndicator', { data });
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(async () => {
      await fetchAndUpdateIndicator();
    });
  }
});
</script>

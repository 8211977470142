<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom" class="mainMenu">
        <ion-tab-button tab="tab1" :class="isTabActive('activities') ? 'tab-active' : ''" @click="handleGoTo('/tabs/activities')">
          <font-awesome-icon icon="fa-solid fa-grid-2" class="tab-icon-bottom" />
        </ion-tab-button>

        <ion-tab-button :class="isTabActive('dash') ? 'tab-active' : ''" tab="tab2" @click="handleGoTo('/tabs/dashboard')">
          <font-awesome-icon icon="fa-solid fa-house" class="tab-icon-bottom" />
        </ion-tab-button>

        <ion-tab-button :id="13" tab="tab3" :class="isTabActive('POSITIONS_RESULTS') ? 'tab-active' : ''" @click="handleOpenModal">
          <font-awesome-icon icon="fa-solid fa-medal" class="tab-icon-bottom" />
        </ion-tab-button>

        <ion-tab-button @click="openPopover">
          <font-awesome-icon icon="fa-solid fa-circle-user" class="tab-icon-bottom" />
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>

    <PositionResultsModal ref="refModalPositionsResults" />
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { IonTabBar, IonTabButton, IonTabs, IonPage, IonRouterOutlet, popoverController } from '@ionic/vue';
import { grid, home } from 'ionicons/icons';
import UserMenu from '@/components/UserMenu.vue';
import PositionResultsModal from '@/components/PositionResultsModal.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'TabsPage',
  components: { IonTabs, IonTabBar, IonTabButton, IonPage, IonRouterOutlet, PositionResultsModal },
  setup() {
    const refModalPositionsResults = ref<null | { handleModalOpenModal: () => null }>(null);
    const router = useRouter();
    const handleGoTo = (path: string) => {
      router.push(`${path}`);
    };

    const handleOpenModal = () => {
      refModalPositionsResults.value?.handleModalOpenModal();
    };

    const isTabActive = (routePathName: string) => router.currentRoute.value.name === routePathName.toLocaleUpperCase();

    return {
      grid,
      handleOpenModal,
      home,
      handleGoTo,
      isTabActive,
      PositionResultsModal,
      refModalPositionsResults
    };
  },
  methods: {
    async openPopover(ev: Event) {
      const popover = await popoverController.create({
        component: UserMenu,
        event: ev,
        side: 'right',
        alignment: 'end',
        componentProps: {
          closePopover: () => {
            popover.dismiss();
          }
        }
      });
      await popover.present();
    }
  }
});
</script>

<style lang="scss" scoped>
.tab-icon-bottom {
  font-size: 38px;
  color: var(--LAVENDER_BLUE);
}

.tab-icon-bottom:hover,
.tab-active svg {
  color: var(--IMPERIAL);
}
</style>

import { PositionResult, Position } from './../models/positionResult.model';
export const createPositionResultAdapter = (_resp: PositionResult) => ({
    me: {
      gradeId: _resp.me.gradeId,
      group: _resp.me.group,
      groupId: _resp.me.groupId,
      index: _resp.me.index,
      school: _resp.me.school,
      schoolId: _resp.me.schoolId,
      score: _resp.me.score,
      stateId: _resp.me.stateId,
      stateNanme: _resp.me.stateNanme,
      status: _resp.me.status,
      student: _resp.me.student,
      studentId: _resp.me.studentId,
    },
    rankList: _resp.rankList.map((_position:Position) =>({
      gradeId: _position.gradeId,
      group: _position.group,
      groupId: _position.groupId,
      index: _position.index,
      school: _position.school,
      schoolId: _position.schoolId,
      score: _position.score,
      stateId: _position.stateId,
      stateNanme: _position.stateNanme,
      status: _position.status,
      student: _position.student,
      studentId: _position.studentId,
    }))
  });
<template>
  <ion-list v-show="!hide">
    <ion-item lines="none" button @click.prevent="handleGoToHelp">
      <ion-label>
        {{ messages.USER_MENU.HELP }}
      </ion-label>
      <ion-icon slot="start" :icon="helpCircleOutline" size="large" />
    </ion-item>

    <ion-item lines="none" button style="display: none">
      <ion-label>
        {{ messages.USER_MENU.SILENCE }}
      </ion-label>
      <ion-icon slot="start" :icon="musicalNotesOutline" size="large" />
    </ion-item>

    <ion-item lines="none" button @click.prevent="handleGoToSofia">
      <ion-label>
        {{ messages.USER_MENU.GO_TO_SOFIAXT }}
      </ion-label>
      <ion-icon slot="start" :icon="powerOutline" size="large" />
    </ion-item>

    <ion-item lines="none" :style="Boolean(welcome?.studentName) ? '' : 'display:none'">
      <ion-label>
        {{ welcome?.studentName }}
      </ion-label>
      <ion-icon slot="start" :icon="personCircle" size="large" />
    </ion-item>
  </ion-list>
</template>

<script lang="ts">
import { IonList, IonItem, IonLabel, IonIcon } from '@ionic/vue';
import { helpCircleOutline, musicalNotesOutline, powerOutline, personCircle, helpBuoyOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import messages from '@/shared/messages';

export default defineComponent({
  components: { IonList, IonItem, IonLabel, IonIcon },
  props: {
    closePopover: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const hide = ref(false);

    const handleCloseUserMenu = () => {
      props.closePopover();
    };

    const handleGoToSofia = () => {
      window.location.href = 'https://www.sofiaxt.com/';
    };

    const welcome = store.getters['welcome/getAllData'];
    const handleGoToHelp = () => {
      router.push('/help');
      handleCloseUserMenu();
    };

    const handleOpenTour = () => {
      hide.value = true;
    };
    return {
      helpBuoyOutline,
      helpCircleOutline,
      musicalNotesOutline,
      powerOutline,
      personCircle,
      router,
      handleGoToSofia,
      handleGoToHelp,
      welcome,
      messages,
      handleOpenTour,
      hide,
      handleCloseUserMenu
    };
  }
});
</script>

<style lang="scss" scoped>
ion-icon {
  color: #c5daff;
}

ion-item:hover ion-icon {
  color: var(--IMPERIAL);
}
</style>
